import actions from "../actions";
import {
  ReservationTypes,
  ReservationModes,
  TransportTypes,
} from "lib/helpers/constants";

const initialState = {
  steps: {
    reservationType: ReservationTypes.NORMAL,
    transportType: TransportTypes.NONE,
    showMenu: false,
    isTransported: false,
    backButton: true,
    shoppingCartNotes: "",
    serviceId: null,
    campaignId: null,
    shopId: null,
    flowTypeArg: null,
    defaultView: "",
    street: "",
    floorDoor: "",
    city: "",
    postalCode: '',
    //TODO: move these fields to an address object
    countryId: 139, //TODO
    deliveryAddressId: null,
    fiscalAddressId: null,
    deliveryType: null,
    shopDeliveryMethodId: null, //TODO
    pickupPoint: null,
    addressName: null,
    latitude: null,
    longitude: null,
    //TODO: fiscalAddress
    mode: null, // One of ReservationModes
    [ReservationModes.BOOK_DATE]: {
      // "book"
      transportInstant: null,
      prizeId: null, // Optional selected discount voucher ("prize")
      numberOfPeople: null,
      selectedDayIsAvailable: false,
      date: null, // The [currently] selected date. Ex: "2020-01-17"
      dateTimes: [
        // {
        //   date: "2020-01-17",
        //   times: [
        //     { time: "11:30", selectedAt: timestamp }
        //   ]
        // }
      ],
    },
    [ReservationModes.BUY_VOUCHER]: {
      // "voucher"
      numberOfPeople: null,
      needVatInvoice: false,
      name: "",
      vatId: "",
      prizeId: null, // Optional selected discount voucher ("prize")
    },
    authentication: {},
    name: "",
    prefix: "+351",
    phone: "",
    notes: "",
    vatId: "",
    mbwayPhoneNumber: "",
    mbwayPrefix: "+351",
    paymentMethod: null, // 'offline' || 'online'
    productOrder: [
      // keyed by shopId
      /*
      {shopId}: {
        shopDeliveryMethodId: null,
        pickupPoint: null,
        note: '',
      }
       */
    ],
  },
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case actions.flow.setStepState.action: {
      const { data } = action.payload;
      return {
        ...state,
        steps: {
          ...state.steps,
          ...data,
          [ReservationModes.BOOK_DATE]: {
            ...state.steps[ReservationModes.BOOK_DATE],
            ...(data[ReservationModes.BOOK_DATE] || {}),
          },
          [ReservationModes.BUY_VOUCHER]: {
            ...state.steps[ReservationModes.BUY_VOUCHER],
            ...(data[ReservationModes.BUY_VOUCHER] || {}),
          },
        },
      };
    }
    case actions.flow.setBackButtonState.action: {
      const backButton = action.payload
      return {
        ...state,
        steps: {
          ...state.steps,
          backButton,
        }
      }
    }
    case `${actions.user.fetchUserWithExternalAuth.action}_FULFILLED`:
    case `${actions.user.fetchUser.action}_FULFILLED`:
    case `${actions.user.login.action}_FULFILLED`:
    case `${actions.user.loginWithFacebook.action}_FULFILLED`: {
      const { firstName, lastName, mobilePrefix, mobile } = action.payload.data;
      return {
        ...state,
        steps: {
          ...state.steps,
          // firstName and lastName might be null, avoid name: "null null"
          name: `${firstName || ""} ${lastName || ""}`.trim(),
          prefix: !mobilePrefix || mobilePrefix.length === 0 ? "+351" : mobilePrefix,
          phone: mobile,
        },
      };
    }
    case `${actions.campaign.fetchCampaign.action}_FULFILLED`: {
      // For takeaway/delivery purchases, we only receive the pre-selected
      // product ids. Here we match these ids with the actual products from
      // the middleware response so that we know the products' names.
      const { isTransported } = state.steps;
      if (!isTransported) {
        return state;
      }

      return {
        ...state,
        steps: {
          ...state.steps,
        },
      };
    }
    default:
      return state;
  }
}
