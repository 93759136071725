import actions from "../actions"

const initialState = {
  entityId: 1,
  env: {
    googleSignInWebClientId: "634737874617-mops0ulu9spv8mjfc1f9v7p12ero1jeh.apps.googleusercontent.com",
    appleSignInClientId: "com.mygon.services.signin",
    facebookAppId: 'MYGONapp',
    supportPhone: '211 306 668',
    supportEmail: 'info@mygon.com',
  },
  social: {},
  theme: {},
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case `${actions.entity.fetchEntityConfiguration.action}_FULFILLED`: {
      return {
        ...action.payload.data,
      }
    }
    default:
      return state
  }
}
